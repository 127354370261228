import React from "react";
import underline1 from "../images/svg/01.svg";
import underline2 from "../images/svg/02.svg";

import styled from "styled-components";
import { randomFromArray } from "../utils/utils";

const Wrapper = styled.span`
  img {
    position: absolute;
    stroke-width: 2;
    width: 100px;
    transform: translate(-100%, -10px);
  }
`;

const Underline = ({ children }) => {
  const drawings = [underline1, underline2];
  let src = randomFromArray(drawings);

  return (
    <Wrapper>
      {children}
      <img alt=" " src={src}></img>
    </Wrapper>
  );
};

export default Underline;
