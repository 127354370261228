import React from "react";
import { useMediaQuery } from "../utils/hooks/useMediaQuery";
import styled from "styled-components";
import ImageSlider from "../components/ImageSlider";
import SanityImage from "gatsby-plugin-sanity-image";

const DesktopWrapper = styled.div`
  width: 90vw;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  justify-items: center;
  align-items: center;
  justify-content: center;
  grid-gap: 3rem;
  padding: 3rem;
  img {
    max-width: 100%;
  }
  div {
  }
`;

const MobileWrapper = styled.div`
  width: 100%;
  margin-bottom: 1rem;
`;

const GridImage = styled.div`
  grid-column: span ${(p) => (p.block ? 6 : 3)};
  width: ${(p) => p.w}%;
  img {
    border-radius: 10px;
  }
`;

const ResponsiveImages = (props) => {
  let isMobile = useMediaQuery("(max-width: 800px)");

  if (isMobile) {
    return (
      <MobileWrapper>
        <ImageSlider
          height={"500px"}
          images={props.images}
          imageWidth={500}
          borderRadius={"6px"}
          // fit={"contain"}
          // bg={"none"}
        ></ImageSlider>
      </MobileWrapper>
    );
  }

  return (
    <DesktopWrapper>
      {props.images.map((image, index) => {
        let size;
        switch (image.gridOptions?.size) {
          case "large":
            size = 75;
            break;

          case "medium":
            size = 55;
            break;

          default:
            size = 35;
            break;
        }
        return (
          <GridImage key={index} w={size} block={image?.gridOptions?.fullWidth}>
            <SanityImage {...image} width={500} alt={image.alt} />
          </GridImage>
        );
      })}
    </DesktopWrapper>
  );
};

export default ResponsiveImages;
