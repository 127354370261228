import React from 'react'
// import imageUrlBuilder from '@sanity/image-url'
import SanityImage from "gatsby-plugin-sanity-image"
// const clientConfig = require('../../client-config')
import styled from 'styled-components'


const Figure = styled.figure`
    padding-top:2rem;
    padding-bottom:2rem;
`


const PortableImage = (props) => {

    return (
        <Figure><SanityImage {...props} width={500} alt="Fo-toe" /></Figure>
    )

    // return (
    //     <figure>
    //         {props.asset && (
    //             <img
    //                 src={imageUrlFor(buildImageObj(props))
    //                     .width(750)
    //                     .url()}
    //                 alt={props.alt}
    //             />
    //         )}
    //     </figure>
    // )
}

export default PortableImage



//functions
// function buildImageObj(source) {
//     const imageObj = {
//         asset: { _ref: source.asset._ref || source.asset._id }
//     }

//     if (source.crop) imageObj.crop = source.crop
//     if (source.hotspot) imageObj.hotspot = source.hotspot

//     return imageObj
// }

// const builder = imageUrlBuilder(clientConfig.sanity)

// function imageUrlFor(source) {
//     return builder.image(source)
// }