import React from "react";
import BlockContent from "@sanity/block-content-to-react";
import clientConfig from "../../client-config";
import styled from "styled-components";
import Underline from "../components/Underline";
import PortableImage from "../components/PortableImage";
import ResponsiveImages from "../components/responsiveImages";

const LineBreak = styled.div`
  height: 2rem;
`;

const serializers = {
  types: {
    code: (props) => (
      <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
    ),
    figure: (props) => <PortableImage {...props.node} />,
    lineBreak: (props) => <LineBreak />,
    responsiveImages: (props) => <ResponsiveImages {...props.node} />,
  },
  marks: {
    underline: (props) => <Underline>{props.children}</Underline>,
  },
};

const BlockContentStyle = styled(BlockContent)`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  h2 {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
    font-family: Passport-Bold;
    align-self: start;
  }
  a {
    text-transform: none;
    font-family: Passport-Bold;
    border-bottom: 1px dotted black;
  }
  figure {
    width: 100%;

    img {
      width: 100%;
      border-radius: 5px;
    }
  }
`;

const PortableText = ({ body }) => {
  return (
    <BlockContentStyle
      blocks={body}
      imageOptions={{ w: 750, h: 750, fit: "max" }}
      serializers={serializers}
      {...clientConfig.sanity}
    />
  );
};

export default PortableText;
