import React from "react";
import styled from "styled-components";
import { Page, GridWrapper } from "../components/styles";
import { graphql } from "gatsby";

import ImageSlider from "../components/ImageSlider";
import PortableText from "../components/PortableText";
import { device } from "../utils/utils";
import LinksContainer from "../components/linksContainer";
import { useMediaQuery } from "../utils/hooks/useMediaQuery";

const Logo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HeroSlider = styled.div`
  height: 80vh;
  @media ${device.tablet} {
    height: 65vh;
  }
`;

const FarmContainer = styled.div`
  max-width: ${(props) => (props.wide ? "1000px" : "750px")};
  margin: auto;
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  @media ${device.tablet} {
    width: calc(100% - 3rem);
    padding: 0rem 1.5rem;
  }
`;

const Farm = ({ data }) => {
  const {
    heroImages,
    heroImagesMobile,
    _rawHeroImages,
    _rawHeroImagesMobile,
    _rawBody,
  } = data.sanityFarm;
  let isMobile = useMediaQuery("(max-width: 800px)");

  return (
    <Page>
      <HeroSlider>
        <ImageSlider
          // images={isMobile ? _rawHeroImagesMobile : _rawHeroImages}
          images={isMobile ? heroImagesMobile : heroImages}
          imageWidth={isMobile ? 800 : 2500}
          useGatsbyImage
        >
          <Logo>
            {/* <Img fixed={data.file.childImageSharp.fixed} alt="logo" /> */}
          </Logo>
        </ImageSlider>
      </HeroSlider>
      <GridWrapper>
        <LinksContainer />
        <FarmContainer>
          <PortableText body={_rawBody} />
        </FarmContainer>
      </GridWrapper>
    </Page>
  );
};

export default Farm;

export const query = graphql`
  {
    sanityFarm {
      title
      _rawHeroImages(resolveReferences: { maxDepth: 10 })
      _rawHeroImagesMobile(resolveReferences: { maxDepth: 10 })
      _rawBody(resolveReferences: { maxDepth: 5 })
      heroImages {
        _rawAsset
        asset {
          fluid(maxWidth: 2200) {
            ...GatsbySanityImageFluid
          }
          metadata {
            palette {
              dominant {
                foreground
                background
              }
            }
          }
        }
        alt
      }
      heroImagesMobile {
        _rawAsset
        asset {
          fluid(maxWidth: 800) {
            ...GatsbySanityImageFluid
          }
          metadata {
            palette {
              dominant {
                foreground
                background
              }
            }
          }
        }
        alt
      }
    }
    file(relativePath: { eq: "images/sr-logo.png" }) {
      relativePath
      absolutePath
      childImageSharp {
        fixed(width: 300) {
          ...GatsbyImageSharpFixed_withWebp_noBase64
        }
      }
    }
  }
`;

// ...GatsbySanityImageFluid
