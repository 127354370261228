import React from "react";
import styled from "styled-components";
import { border, device } from "../utils/utils";
import MyLink from "./mylink";
import { useStaticQuery, graphql } from "gatsby";

const LinkContainer = styled.div`
  position: sticky;
  top: 4rem;
  display: flex;
  flex-direction: column;
  width: fit-content;
  min-width: 200px;
  height: fit-content;
  margin: 1rem 3rem;
  a {
    border-bottom: ${border};
    border-color: ${(props) => props.theme.stoneBlack};
    padding: 1rem 0rem;
  }
  @media ${device.tablet} {
    display: none;
    /* position:inherit;
    width: inherit;
    margin: none;
    margin: none;
    margin: 0;
    padding: 1em;
    grid-row: 2; */
  }
`;

const LinksContainer = () => {
  const data = useStaticQuery(graphql`
    {
      sanitySiteSettings {
        instagram
        medium
      }
    }
  `);
  const {
    sanitySiteSettings: { instagram, medium },
  } = data;

  return (
    <LinkContainer>
      <MyLink to={instagram}>Instagram</MyLink>
      <MyLink to="/press" internal>
        Press
      </MyLink>
      <MyLink to="/products" internal>
        Products
      </MyLink>
      <MyLink to={medium}>Thoughts</MyLink>
    </LinkContainer>
  );
};

export default LinksContainer;
